import React from 'react';
import LayoutSecondary from '../components/layout/LayoutSecondary';

const NotFound = () => (
  <LayoutSecondary>
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex text-sm">
        <h1 className="blocktext-4xl lg:text-5xl xl:text-6xl font-bold leading-none w-full">
          No worries ;-)
        </h1>
        <p>
          Some things need to be thought over... Feel free to come back any time.
          And don't forget you can always <strong>try</strong> punch-in for free!
        </p>
      </div>
    </section>
  </LayoutSecondary>
);

export default NotFound;
